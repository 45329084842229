@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro);
#root {
  height: 100%;
}
.heroImage {
  height: 30rem;
  object-fit: cover;
  width: 100%;
}

.ui.button.startupHeroGetStarted {
  margin-left: 30%;
  width: 40%;
  margin-right: 30%;
  margin-top: 2rem;
}

.card {
  text-align: center;
}

h1.heroHeader {
  position: absolute;
  top: 20rem;
  font-size: 4rem !important;
  align-items: center;
  color: white !important;
  background: black !important;
}

.ui.form.footer-form {
  margin: auto;
  width: 40%;
}

.ui.header.email-cta {
  color: white;
}

.ui.header.footer-cta {
  color: white;
  margin:auto;
  width: 40%;
}

.message.footer-msg {
  width: 90%;
  margin: auto;
}


div.beta-banner {
  background-color: white;
}

div.beta-banner.cta {
  cursor: pointer;
}

.beta-banner img.ui.image {
  margin: auto;
  padding: 1rem 0;
}

div.beta-body {
  width: auto;
  height: 145%;
  background-image: url(/static/media/betaBg.736cc404.png);
  background-size: 100% 70rem;
  background-repeat: no-repeat;
}

div.ui.container.signup-container.cta {
  width: auto;
}

div.field.ctaButton {
  text-align: center;

}

div.ui.container.main-container {
  width: auto !important;
  display: flex;
  justify-content: center;
  height: 10rem;
}

.wrapper {
  height: 100vh;
  /*This part is important for centering*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.typing-demo {
  border-right: solid 3px rgba(0,255,0,.75);
  white-space: nowrap;
  overflow: hidden;    
  font-family: 'Source Code Pro', monospace;  
  font-size: 28px;
  color: rgba(255,255,255,.70);
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Animation */
.typing-demo {
  -webkit-animation: animated-text 4s steps(37,end) 1s 1 normal both,
             animated-cursor 600ms steps(37,end) infinite;
          animation: animated-text 4s steps(37,end) 1s 1 normal both,
             animated-cursor 600ms steps(37,end) infinite;
}

/* text animation */

@-webkit-keyframes animated-text{
  from{width: 100px;}
  to{width: 625px;}
}

@keyframes animated-text{
  from{width: 100px;}
  to{width: 625px;}
}

/* cursor animations */

@-webkit-keyframes animated-cursor{
  from{border-right-color: rgba(0,255,0,.75);}
  to{border-right-color: transparent;}
}

@keyframes animated-cursor{
  from{border-right-color: rgba(0,255,0,.75);}
  to{border-right-color: transparent;}
}

div.beta-body h1.ui.header {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 2rem;
  color: white;
}

div.beta-body h2.ui.header {
  text-align: center;
  font-size: 2rem;
  width: 20rem;
  margin: auto;
}

.ui.card {
  box-shadow: none;
}
.ui.blue.card {
  background-color: #7e9bec;
  box-shadow: none;
  margin-right: 0.25rem;
}
.ui.blue.card div.content div.header {
  color: white;
}
.ui.blue.card div.content div.description {
  color: white;
  font-size: 1.25rem;
}

.ui.red.card {
  background-color: #e56953;
  box-shadow: none;
  margin-left: 0.25rem;
}
.ui.red.card div.content div.header {
  color: white;
}
.ui.red.card div.content div.description {
  color: white;
  font-size: 1.25rem;
}
div.beta-body h1.ui.header.cta {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 3rem;
  color: white;
}

div.hiw-container-invest {
  text-align: center;
  text-align: -webkit-center;
  width: 50%;
  float: left;
  margin-bottom: 5rem;
}

div.hiw-container-startup {
  text-align: center;
  text-align: -webkit-center;
  width: 50%;
  float: right;
}

.ui.container.footer {
  display: grid;
  padding-top: 5rem;
  margin: auto;
  background-color: #404464;
  width: 100% !important;
}

@media only screen and (min-width: 768px) {

  .message.footer-msg {
    width: 40%;
    margin: auto;
  }
  div.beta-body h1.ui.header {
    padding-top: 10rem;
    font-size: 5rem;
  }
  .ui.container.how-work-container {
  width: 45%;
  }

  div.beta-body h2.ui.header {
    text-align: center;
    font-size: 4rem;
    width: 50rem;
    margin: auto;
  }
  

  .ui.card.main-investor {
    margin-right: 3rem;
    margin-bottom: 0;
  }
  .ui.card.main-startup {
    margin-left: 3rem;
    margin-top: 0;
  }

  div.beta-body h1.ui.header.cta {
    font-size: 5rem;
  }
  
  
  div.ui.container.main-container {
    width: 80% !important;
    height: 10rem;
  }

  div.beta-body h2.ui.header.copy.cta {
    width: 30%;
  }

  div.ui.container.signup-container.cta {
    width: 20% !important;
  }
}


div.beta-body h2.ui.header.copy {
  text-align: center;
  padding-bottom: 2rem;
  color: white;
  width: 30%;
  margin: auto;
}

div.beta-body h2.ui.header.copy.cta {
  text-align: center;
  padding-bottom: 2rem;
  color: white;
  width: 75%;
  margin: auto;
}

.ui.card.main-investor {
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.ui.card.main-startup {
  margin-left: 0.5rem;
  margin-top: 0;
}

img.ui.image.startup-image {
  height: 100%;
}

span.main-startup-header {
  position: absolute;
    z-index: 1;
    width: 100%;
    font-size: 3rem;
    line-height: 5rem;
    padding-top: 5rem;
    height: 100%;
    color: white;
}

span.main-investor-header {
  position: absolute;
    z-index: 1;
    width: 100%;
    font-size: 3rem;
    line-height: 5rem;
    padding-top: 5rem;
    height: 100%;
    color: white;
}


.invest {
  position: relative;
  width: 20em;
  height: 20em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.start {
  position: relative;
  width: 20em;
  height: 20em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image {
  width: 100%;
  height: 100%;
}

.invest:before {
  cursor: pointer;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 20em;
  height: 10em;
  background: rgba(40, 89, 224, 0.6);
  width: 100%;
}

.start:before {
  cursor: pointer;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 20em;
  height: 10em;
  background: rgba(223, 51, 21, 0.6);
  width: 100%;
}
.text {
  font-family: Arial;
  font-size: 2em;
  color: white;
  text-shadow: 0.1em 0.1em 0.5em rgba(0,0,0,0.5);
  margin: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
}
